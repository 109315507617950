import "core-js/modules/es.array.push.js";
export default {
  data() {
    return {
      list: [],
      curCenterIndex: 0,
      //个人中心中当前选中项 
      curSetIndex: -1,
      //设置中当前选中
      personList: [],
      //个人中心
      setList: [],
      //设置
      isBindingModal: '' //发布信息时是否验证
    };
  },

  mounted() {
    this.getPersonAndSetData();

    // 监听localstorage里面的数据
    window.addEventListener("setItemEvent", e => {
      if (e.key === "lang") {
        this.getPersonAndSetData();
      }
    });

    // 保证刷新后，当前选中项不会被重置
    if (Object.prototype.hasOwnProperty.call(this.$route.query, "curCenterIndex")) {
      this.curSetIndex = -1;
      this.curCenterIndex = this.$route.query.curCenterIndex;
    } else {
      this.curCenterIndex = -1;
      this.curSetIndex = this.$route.query.curSetIndex;
    }
    this.getUserInfo();
  },
  methods: {
    // 个人信息
    getUserInfo() {
      this.$http.userInfo().then(res => {
        if (res.code == 1) {
          // 是否完善
          this.isBindingModal = res.data.authentication == 0 ? true : false;
        } else if (res.code == -201) {} else {
          this.$message.error(res.msg);
        }
      });
    },
    // 个人中心和设置数据
    getPersonAndSetData() {
      this.personList = [this.$t('title.personInfor'), this.$t('other.myMessage'), this.$t('title.memberCenter'), this.$t('inforOrder'), this.$t('adOrder'), this.$t('vipOrder'), this.$t('title.myRelease'), this.$t('myAd'), this.$t('title.myCollect'), this.$t('talent'), this.$t('other.helpCenter')];
      console.log(this.personList, '接口');
    },
    // 个人中心点击
    itemClick(index) {
      console.log(this.isBindingModal);
      this.curCenterIndex = index;
      this.curSetIndex = -1;
      switch (index * 1) {
        case 0:
          // 个人信息
          this.$router.push({
            path: '/personInfor',
            query: {
              curCenterIndex: 0
            }
          });
          // if (this.isBindingModal) {
          // 	this.$router.push({
          // 		path: '/verifyPage',
          // 		query: {
          // 			curCenterIndex: 0,
          // 		},
          // 	})
          // } else {
          // 	this.$router.push({
          // 		path: '/personInfor',
          // 		query: {
          // 			curCenterIndex: 0,
          // 		},
          // 	})
          // }
          break;
        case 1:
          // 我的信息
          this.$router.push({
            path: '/myMessage',
            query: {
              curCenterIndex: 1
            }
          });
          break;
        case 2:
          // 会员中心
          this.$router.push({
            path: '/vip',
            query: {
              curCenterIndex: 2
            }
          });
          break;
        case 3:
          // 信息订单
          this.$router.push({
            path: '/inforOrder',
            query: {
              curCenterIndex: 3
            }
          });
          break;
        case 4:
          // 广告订单
          this.$router.push({
            path: '/adOrder',
            query: {
              curCenterIndex: 4
            }
          });
          break;
        case 5:
          this.$router.push({
            path: '/vipOrder',
            query: {
              curCenterIndex: 5
            }
          });
          break;
        case 6:
          // 我的发布
          this.$router.push({
            path: '/myRelease',
            query: {
              curCenterIndex: 6
            }
          });
          break;
        case 7:
          // 我的广告
          this.$router.push({
            path: '/myAd',
            query: {
              curCenterIndex: 7
            }
          });
          break;
        case 8:
          // 我的收藏
          this.$router.push({
            path: '/collect',
            query: {
              curCenterIndex: 8
            }
          });
          break;
        case 9:
          // 人才招聘
          this.$router.push({
            path: '/talentRecruitment',
            query: {
              curCenterIndex: 9
            }
          });
          break;
        case 10:
          // 帮助中心
          this.$router.push({
            path: '/helpCenter',
            query: {
              curCenterIndex: 10
            }
          });
          break;
      }
    },
    // 设置中当前选中
    itemClick2(index) {
      this.curSetIndex = index;
      this.curCenterIndex = -1;
      switch (index * 1) {
        case 0:
          this.$router.push({
            path: '/editPassword1',
            query: {
              curSetIndex: 0
            }
          });
          break;
        case 1:
          this.$router.push({
            path: '/editPassword2',
            query: {
              curSetIndex: 1
            }
          });
          break;
        case 2:
          this.$router.push({
            path: '/editBindingPhone',
            query: {
              curSetIndex: 2
            }
          });
          break;
        case 3:
          this.$router.push({
            path: '/editBindingEmail',
            query: {
              curSetIndex: 3
            }
          });
          break;
        case 4:
          this.$router.push({
            path: '/bindingPhone',
            query: {
              curSetIndex: 4
            }
          });
          break;
        case 5:
          this.$router.push({
            path: '/bindingEmail',
            query: {
              curSetIndex: 5
            }
          });
          break;
        case 6:
          this.$router.push({
            path: '/adviceFeedback',
            query: {
              curSetIndex: 6
            }
          });
          break;
        case 7:
          this.$router.push({
            path: '/aboutUs',
            query: {
              curSetIndex: 7
            }
          });
          break;
        // case 8:
        // 	this.$router.push({
        // 		path: '/contactUs',
        // 		query: {
        // 			curSetIndex: 8,
        // 		}
        // 	})
        // 	break;
      }
    }
  }
};