var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "page"
  }, [_c('div', {
    staticClass: "person"
  }, [_c('div', {
    staticClass: "person_left"
  }, [_c('div', {
    staticClass: "person_title"
  }, [_vm._m(0), _c('div', {
    staticClass: "person_title_name"
  }, [_vm._v(_vm._s(_vm.$t('title.personCenter')))])]), _c('div', {
    staticClass: "all_center"
  }, _vm._l(_vm.personList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "center_item",
      on: {
        "click": function ($event) {
          return _vm.itemClick(index);
        }
      }
    }, [_c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.curCenterIndex == index,
        expression: "curCenterIndex==index"
      }],
      staticClass: "center_item_img",
      class: _vm.curCenterIndex == index ? 'none' : ''
    }), _c('div', {
      class: ['center_item_name', _vm.curCenterIndex == index ? 'select' : 'un_select']
    }, [_vm._v(_vm._s(item))]), _vm.curCenterIndex == index ? _c('img', {
      staticClass: "jiantou",
      attrs: {
        "src": require("../../assets/mycenter/active_item.png")
      }
    }) : _c('img', {
      staticClass: "jiantou",
      attrs: {
        "src": require("../../assets/mycenter/item.png")
      }
    })]);
  }), 0), _c('div', {
    staticClass: "set"
  }, [_vm._m(1), _c('div', {
    staticClass: "set_title"
  }, [_vm._v(" " + _vm._s(_vm.$t('title.set')) + " ")])]), _c('div', {
    staticClass: "all_center"
  }, [_c('div', {
    staticClass: "center_item",
    on: {
      "click": function ($event) {
        return _vm.itemClick2(0);
      }
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.curSetIndex == 0,
      expression: "curSetIndex==0"
    }],
    staticClass: "center_item_img",
    class: _vm.curSetIndex == 0 ? 'none' : ''
  }), _c('div', {
    class: ['center_item_name', _vm.curSetIndex == 0 ? 'select' : 'un_select']
  }, [_vm._v(" " + _vm._s(_vm.$t('set.phoneAuthentication')) + " ")]), _vm.curSetIndex == 0 ? _c('img', {
    staticClass: "jiantou",
    attrs: {
      "src": require("../../assets/mycenter/active_item.png")
    }
  }) : _c('img', {
    staticClass: "jiantou",
    attrs: {
      "src": require("../../assets/mycenter/item.png")
    }
  })]), _c('div', {
    staticClass: "center_item",
    on: {
      "click": function ($event) {
        return _vm.itemClick2(2);
      }
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.curSetIndex == 2,
      expression: "curSetIndex==2"
    }],
    staticClass: "center_item_img",
    class: _vm.curSetIndex == 2 ? 'none' : ''
  }), _c('div', {
    class: ['center_item_name', _vm.curSetIndex == 2 ? 'select' : 'un_select']
  }, [_vm._v(" " + _vm._s(_vm.$t('set.editPhone')) + " ")]), _vm.curSetIndex == 2 ? _c('img', {
    staticClass: "jiantou",
    attrs: {
      "src": require("../../assets/mycenter/active_item.png")
    }
  }) : _c('img', {
    staticClass: "jiantou",
    attrs: {
      "src": require("../../assets/mycenter/item.png")
    }
  })]), _c('div', {
    staticClass: "center_item",
    on: {
      "click": function ($event) {
        return _vm.itemClick2(5);
      }
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.curSetIndex == 5,
      expression: "curSetIndex==5"
    }],
    staticClass: "center_item_img",
    class: _vm.curSetIndex == 5 ? 'none' : ''
  }), _c('div', {
    class: ['center_item_name', _vm.curSetIndex == 5 ? 'select' : 'un_select']
  }, [_vm._v(" " + _vm._s(_vm.$t('set.bindingEmail')) + " ")]), _vm.curSetIndex == 5 ? _c('img', {
    staticClass: "jiantou",
    attrs: {
      "src": require("../../assets/mycenter/active_item.png")
    }
  }) : _c('img', {
    staticClass: "jiantou",
    attrs: {
      "src": require("../../assets/mycenter/item.png")
    }
  })]), _c('div', {
    staticClass: "center_item",
    on: {
      "click": function ($event) {
        return _vm.itemClick2(6);
      }
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.curSetIndex == 6,
      expression: "curSetIndex==6"
    }],
    staticClass: "center_item_img",
    class: _vm.curSetIndex == 6 ? 'none' : ''
  }), _c('div', {
    class: ['center_item_name', _vm.curSetIndex == 6 ? 'select' : 'un_select']
  }, [_vm._v(" " + _vm._s(_vm.$t('set.adviceFeedback')) + " ")]), _vm.curSetIndex == 6 ? _c('img', {
    staticClass: "jiantou",
    attrs: {
      "src": require("../../assets/mycenter/active_item.png")
    }
  }) : _c('img', {
    staticClass: "jiantou",
    attrs: {
      "src": require("../../assets/mycenter/item.png")
    }
  })]), _c('div', {
    staticClass: "center_item",
    on: {
      "click": function ($event) {
        return _vm.itemClick2(7);
      }
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.curSetIndex == 7,
      expression: "curSetIndex==7"
    }],
    staticClass: "center_item_img",
    class: _vm.curSetIndex == 7 ? 'none' : ''
  }), _c('div', {
    class: ['center_item_name', _vm.curSetIndex == 7 ? 'select' : 'un_select']
  }, [_vm._v(" " + _vm._s(_vm.$t('set.aboutUs')) + " ")]), _vm.curSetIndex == 7 ? _c('img', {
    staticClass: "jiantou",
    attrs: {
      "src": require("../../assets/mycenter/active_item.png")
    }
  }) : _c('img', {
    staticClass: "jiantou",
    attrs: {
      "src": require("../../assets/mycenter/item.png")
    }
  })])])]), _c('div', {
    staticClass: "person_right"
  }, [_c('router-view')], 1)])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "person_img"
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/mycenter/person.png")
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "set_img"
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/person/set.png")
    }
  })]);
}];
export { render, staticRenderFns };